import React from 'react';

export const Footer = () => {
  return (
    <footer className="bg-gradient-to-r from-edvouch-teal to-edvouch-blue text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0">
            <h2 className="text-2xl font-bold">Edvouch</h2>
            <p className="mt-2">Blockchain-based Credential Verification Platform</p>
          </div>
          <nav className="flex space-x-4">
            <a href="/privacy-policy" className="hover:text-edvouch-navy transition duration-300">Privacy Policy</a>
            <a href="/terms-of-service" className="hover:text-edvouch-navy transition duration-300">Terms of Service</a>
            <a href="/contact" className="hover:text-edvouch-navy transition duration-300">Contact Us</a>
          </nav>
        </div>
        <div className="mt-8 text-center">
          <p>&copy; {new Date().getFullYear()} Edvouch. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};