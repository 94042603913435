import React from 'react';
import { BackgroundWatermark } from '../components/BackgroundWatermark';
import { Target, List, Lightbulb } from 'lucide-react';

const AboutSection = ({ title, content, icon: Icon }) => (
  <div className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center text-center">
    <Icon className="w-12 h-12 text-edvouch-red mb-4" />
    <h2 className="text-2xl font-semibold text-edvouch-teal mb-4">{title}</h2>
    {Array.isArray(content) ? (
      <ul className="text-gray-700 list-disc list-inside text-left">
        {content.map((item, index) => (
          <li key={index} className="mb-2">{item}</li>
        ))}
      </ul>
    ) : (
      <p className="text-gray-700">{content}</p>
    )}
  </div>
);

export const About = () => {
  const sections = [
    {
      title: "Our Mission",
      content: "Edvouch aims to revolutionize the way educational credentials are issued, managed, and verified globally. We strive to eliminate credential fraud, streamline verification processes, and enhance trust in the academic credentialing system.",
      icon: Target
    },
    {
      title: "Key Objectives",
      content: [
        "Eliminate Credential Fraud",
        "Streamline Verification Process",
        "Enhance Global Mobility",
        "Empower Students",
        "Improve Institutional Efficiency",
        "Foster Trust"
      ],
      icon: List
    },
    {
      title: "Our Approach",
      content: "By leveraging blockchain technology, we provide a comprehensive, trusted, and user-centric solution that transforms the academic credentialing landscape, empowering students, institutions, and employers worldwide.",
      icon: Lightbulb
    }
  ];

  return (
    <BackgroundWatermark>
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold text-edvouch-blue mb-8 text-center">About Edvouch</h1>
      
      <div className="max-w-4xl mx-auto mb-12 text-center">
        <p className="text-2xl text-gray-700 mb-4">
          At Edvouch, we're not just creating a platform; we're building a movement towards a more 
          <span className='text-edvouch-blue'> transparent,</span> 
          <span className='text-edvouch-teal'> efficient, </span> and 
          <span className='text-edvouch-red'> trustworthy </span>
          credentialing ecosystem.
        </p>
        <p className="text-lg text-gray-700">
          Whether you're an educational institution, an employer, or a student, we invite you to be part of this transformative journey.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {sections.map((section, index) => (
          <AboutSection key={index} {...section} />
        ))}
      </div>
    </div>
  </BackgroundWatermark>
  );
};