// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { Menu, X } from 'lucide-react';
// import logo from '../assets/logo.png'; // Adjust this path if necessary

// export const Navbar = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   return (
//     <nav className="bg-edvouch-navy">
//       <div className="container mx-auto px-4">
//         <div className="flex justify-between items-center py-4">
//           <Link to="/" className="flex items-center space-x-2">
//             <img src={logo} alt="Edvouch Logo" className="h-8 w-auto" /> {/* Adjust size as needed */}
//             <span className="text-2xl font-bold bg-gradient-to-r from-edvouch-red via-edvouch-teal to-edvouch-blue text-transparent bg-clip-text">
//               edvouch
//             </span>
//           </Link>
//           <div className="hidden md:flex space-x-6">
//             <NavLink to="/">Home</NavLink>
//             <NavLink to="/about">About</NavLink>
//             <NavLink to="/features">Features</NavLink>
//             <NavLink to="/contact">Contact</NavLink>
//           </div>
//           <button className="md:hidden text-white" onClick={() => setIsOpen(!isOpen)}>
//             {isOpen ? <X size={24} /> : <Menu size={24} />}
//           </button>
//         </div>
//       </div>
//       {isOpen && (
//         <div className="md:hidden">
//           <NavLink to="/" mobile>Home</NavLink>
//           <NavLink to="/about" mobile>About</NavLink>
//           <NavLink to="/features" mobile>Features</NavLink>
//           <NavLink to="/contact" mobile>Contact</NavLink>
//         </div>
//       )}
//     </nav>
//   );
// };

// const NavLink = ({ to, children, mobile }) => (
//   <Link
//     to={to}
//     className={`text-white hover:text-edvouch-red transition duration-300 ${
//       mobile ? 'block py-2 px-4 hover:bg-edvouch-teal' : ''
//     }`}
//   >
//     {children}
//   </Link>
// );





import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import logo from '../assets/icon.png'; // Adjust this path if necessary




export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-gradient-to-r from-edvouch-teal to-edvouch-blue">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center py-4">
          <Link to="/" className="flex items-center space-x-2">
            <img src={logo} alt="Edvouch Logo" className="h-8 w-auto" />
            <span className="text-2xl font-bold text-white">
              edvouch
            </span>
          </Link>
          <div className="hidden items-center md:flex space-x-6">
            <NavLink to="/">Home</NavLink>
            <NavLink to="/about">About</NavLink>
            <NavLink to="/features">Features</NavLink>
            <NavLink to="/contact">Contact</NavLink>
          </div>
          <button className="md:hidden text-white" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden m-3 ">
          <NavLink  to="/" mobile>Home</NavLink>
          <NavLink    to="/about" mobile>About</NavLink>
          <NavLink  to="/features" mobile>Features</NavLink>
          <NavLink   to="/contact" mobile>Contact</NavLink>
        </div>
      )}
    </nav>
  );
};

const NavLink = ({ to, children, mobile }) => (
  <Link
    to={to}
    className={`text-white hover:text-edvouch-red transition duration-300 ${
      mobile ? 'block py-2 px-4 hover:bg-edvouch-navy bg-opacity-20' : ''
    }`}
  >
    {children}
  </Link>
);