"use client";

import { useEffect, useState, useCallback } from "react";

// const mergeClassNames = (...classes) => {
//   return classes.filter(Boolean).join(' ');
// };

export default function TypingAnimation({ children, duration = 200, className }) {
  const [displayedText, setDisplayedText] = useState("");
  const text = children?.toString() || "";

  const animateTyping = useCallback(() => {
    let i = 0;
    const typingEffect = setInterval(() => {
      if (i < text.length) {
        setDisplayedText((prev) => text.substring(0, prev.length + 1));
        i++;
      } else {
        clearInterval(typingEffect);
      }
    }, duration);

    return () => clearInterval(typingEffect);
  }, [text, duration]);

  useEffect(() => {
    setDisplayedText(""); // Reset the displayed text when the children change
    const cleanup = animateTyping();
    return cleanup;
  }, [text, animateTyping]);

  return (
    <span className={className}>
      {displayedText}
      <span className="invisible">{text.substring(displayedText.length)}</span>
    </span>
  );
}