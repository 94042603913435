import React from 'react';
import { Lock, Clock, Globe, User, Shield, Eye } from 'lucide-react';
import { BackgroundWatermark } from '../components/BackgroundWatermark';

const Feature = ({ title, description, icon: Icon }) => (
  <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-md">
    <Icon className="w-12 h-12 text-edvouch-red mb-4" />
    <h3 className="text-xl font-semibold text-edvouch-teal mb-2">{title}</h3>
    <p className="text-gray-600 text-center">{description}</p>
  </div>
);

export const Features = () => {
  const features = [
    {
      title: "Secure Digital Credentials",
      description: "Digitize and secure academic credentials using advanced cryptographic techniques.",
      icon: Lock,
    },
    {
      title: "Instant Verification",
      description: "Enable real-time, efficient verification of credentials for authorized parties.",
      icon: Clock,
    },
    {
      title: "Global Accessibility",
      description: "Provide a standardized, interoperable platform accessible worldwide.",
      icon: Globe,
    },
    {
      title: "User Control and Privacy",
      description: "Grant full control over digital credentials, ensuring privacy and consent management.",
      icon: User,
    },
    {
      title: "Institutional Integration",
      description: "Offer seamless integration for educational institutions to issue and manage digital credentials.",
      icon: Shield,
    },
    {
      title: "Transparent Process",
      description: "Provide a clear, traceable validation trail for every credential verification.",
      icon: Eye,
    },
  ];

  return (
    <BackgroundWatermark>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-edvouch-blue mb-8 text-center">Edvouch Features</h1>
        {/* <h1 className="text-2xl font-bold bg-gradient-to-r from-edvouch-red via-edvouch-teal to-edvouch-blue  text-center">Edvouch Features</h1> */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <Feature key={index} {...feature} />
          ))}
        </div>
      </div>
    </BackgroundWatermark>
  );
};