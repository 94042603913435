import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Shield, Globe, Clock, User } from 'lucide-react';
import { BackgroundWatermark } from '../components/BackgroundWatermark';
import TypingAnimation from '../components/typing_animation';

const GradientButton = ({ to, children }) => (
  <Link 
    to={to} 
    className="bg-gradient-to-r from-edvouch-teal to-edvouch-blue text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 hover:opacity-90 inline-block"
  >
    {children}
  </Link>
);

const GradientHeading = ({ children, className = "" }) => (
  <h1 className={`text-7xl font-bold bg-gradient-to-r from-edvouch-teal to-edvouch-blue text-transparent bg-clip-text leading-tight animate-gradient ${className}`}>
    {children}
  </h1>
);

const FeatureCard = ({ icon: Icon, title }) => (
  <div className="bg-gradient-to-br from-white to-edvouch-blue/10 border border-edvouch-blue/20 p-6 rounded-lg text-center shadow-md hover:shadow-lg transition-shadow duration-300">
    <Icon className="w-12 h-12 mx-auto mb-4 text-edvouch-red" />
    <h3 className="text-lg font-semibold text-edvouch-teal">{title}</h3>
  </div>
);

export const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handlePasswordSubmit = () => {
    if (password === process.env.REACT_APP_DEMO_PASSWORD) {
      window.location.href = process.env.REACT_APP_DEMO_URL;
    } else {
      setError('Incorrect password. Please try again.');
    }
  };

  return (
    <BackgroundWatermark>
      <div className="text-edvouch-navy min-h-screen">
        <section className="container mx-auto px-4 py-16 text-center flex flex-col items-center space-y-8">
          <div className="space-y-4">
            <GradientHeading>
              <TypingAnimation>
                Digitize. Verify. Access.
              </TypingAnimation>
            </GradientHeading>
          </div>

          <GradientButton to="/features">Explore Features</GradientButton>

          <h6 className="text-3xl font-bold text-edvouch-blue bg-clip-text">
            Transforming credentials into 
            <span className='text-edvouch-red'> Digitally </span> 
            <span className='text-edvouch-red'> Verified </span>
            <span className='text-edvouch-red'> Assets </span> 
            unlocking global potential
          </h6>
          <div className='flex flex-col items-center space-y-0'>
            <p className="text-black-500 dark:text-black-400">
              Want to see edvouch in action? <span> </span>
              <button 
                onClick={() => setIsModalOpen(true)} 
                className="inline-flex items-center font-medium text-blue-600 dark:text-blue-500 hover:underline"
              >
                Visit demo
                <svg className="w-4 h-4 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                </svg>
              </button>
            </p>
          </div>
        </section>

        {/* Password Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold mb-4">Enter Password</h2>
              <input 
                type="password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                className="w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
              />
              {error && <p className="text-red-500 mb-4">{error}</p>}
              <div className="flex justify-end space-x-4">
                <button 
                  onClick={() => setIsModalOpen(false)} 
                  className="px-4 py-2 bg-gray-300 rounded-md"
                >
                  Cancel
                </button>
                <button 
                  onClick={handlePasswordSubmit} 
                  className="px-4 py-2 bg-blue-600 text-white rounded-md"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}

        <section className="container mx-auto px-4 py-16">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div className="space-y-6">
              <h2 className="text-3xl font-semibold bg-gradient-to-r from-edvouch-red via-edvouch-teal to-edvouch-blue text-transparent bg-clip-text">
                Our Vision
              </h2>
              <p className="text-xl text-edvouch-navy">
                To forge a global ecosystem where academic and professional credentials are securely digitized, inherently trusted, instantly verifiable, and universally recognized, enabling seamless access to opportunities worldwide.
              </p>
              <GradientButton to="/about">Learn more about us</GradientButton>
            </div>
            <div className="grid grid-cols-2 gap-6">
              <FeatureCard icon={Shield} title="Secure Credentials" />
              <FeatureCard icon={Globe} title="Global Accessibility" />
              <FeatureCard icon={Clock} title="Instant Verification" />
              <FeatureCard icon={User} title="User Control" />
            </div>
          </div>
        </section>
      </div>
    </BackgroundWatermark>
  );
};