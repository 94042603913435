import React, { useState } from 'react';
import { BackgroundWatermark } from '../components/BackgroundWatermark';

export const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('/.netlify/functions/sendEmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        alert('Email sent successfully!');
      } else {
        alert('Failed to send email.');
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  return (
    <BackgroundWatermark>
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-edvouch-blue mb-8 text-center">Contact Us</h1>
        
        <div className="max-w-3xl mx-auto mb-12 text-center">
          <h2 className="text-2xl font-semibold text-edvouch-teal mb-4">Join Us in Shaping the Future of Credentials</h2>
          <p className="text-lg text-gray-700">
            Together, we can create a world where credentials are universally trusted, easily verifiable, and empower individuals to showcase their true potential.
          </p>
        </div>
        
        <div className="max-w-md mx-auto bg-white rounded-lg shadow-md p-8">
          <p className="text-gray-700 mb-6">
            For more information about Edvouch, please contact our team:
          </p>
          
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name" className="block text-gray-700 font-semibold mb-2">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-edvouch-teal"
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-gray-700 font-semibold mb-2">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-edvouch-teal"
                required
              />
            </div>
            <div>
              <label htmlFor="message" className="block text-gray-700 font-semibold mb-2">Message</label>
              <textarea
                id="message"
                name="message"
                rows="4"
                value={formData.message}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-edvouch-teal"
                required
              ></textarea>
            </div>
            <button type="submit" className="w-full bg-edvouch-red text-white py-2 px-4 rounded-md hover:bg-opacity-90 transition duration-300">
              Send Message
            </button>
          </form>
        </div>
      </div>
    </BackgroundWatermark>
  );
};