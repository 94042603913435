import React from 'react';
import logoImage from '../assets/logo.png';

export const BackgroundWatermark = ({ children }) => {
  return (
    <div className="relative min-h-screen overflow-hidden">
      <div 
        className="absolute inset-0 opacity-5 pointer-events-none"
        style={{
          backgroundImage: `url(${logoImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: '80% auto',
          backgroundAttachment: 'fixed'
        }}
      ></div>
      <div className="relative z-10">
        {children}
      </div>
    </div>
  );
};